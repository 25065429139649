import React from 'react'
import CaseStudies from '../../components/case-studies'

const Manimegalan = () => {
    return (
        <CaseStudies 
        title="Manimegalan | Case Study | Crampete"
        imgSrc = "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/case-studies/student-case-study-manimegalan.jpg"
        altText = "Manimegalan"
        />
    )
}

export default Manimegalan;
